import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 自适应文件引入
import "./lib/lib-flexible"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 公共css
import './assets/style/com.css'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
// Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
// 阿里图标库
import './assets/iconfont/iconfont.css'
//导入请求包axios
import axios from 'axios'

// 引入动画
import animated from 'animate.css'
import 'animate.css/animate.min.css'
import './lib/swiper-animation/animate.min.css'

// wowjs动画
import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'
Vue.prototype.$wow = wow

// 百度地图
import BaiduMap from "vue-baidu-map"
Vue.use(BaiduMap,{
  ak: 'AB2G2M10SK3Gd7c6BAGjRDSKWfzePNXF'
})

// 图片懒加载
// import VueLazyload from "vue-lazyload";
// //使用，自定义配置插件
// Vue.use(VueLazyload, {
// preLoad: 1.3,//预压高度比例
// error: require('@/assets/image/public/error.png'),//指定加载失败时图片
// loading: require('@/assets/image/public/loading.jpg'),//指定加载图片
// attempt: 1,  //尝试计数
// })

// 无缝滚动
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)


Vue.prototype.$http = axios //挂载全局，可以通过$http来发起请求
Vue.prototype.router = router;//挂载路由

Vue.config.productionTip = false

Vue.use(ElementUI);
// Vue.use(animated)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


