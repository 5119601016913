<template>
  <!-- 侧边定位 -->
  <div class="aside" v-show="showGoTop">
    <div class="gzh">
      <div class="rImg">
        <div class="img"></div>
        <span>公众号</span>
      </div>
      <div class="gzhLeft">
        <img src="@/assets/image/home/footerGzh.gif" alt="">
      </div>
    </div>
    <div class="tel">
      <div class="rImg">
        <div class="img"></div>
        <!-- <img src="@/assets/image/home/aside/telBlue.png" alt=""> -->
        <span>电话咨询</span>
      </div>
      <div class="telLeft">
        <div class="telBox">
          <div class="text">
            <img src="@/assets/image/home/TelOne.png" alt="" />技术服务热线
          </div>
          <div class="number">15801337792</div>
        </div>
        <div class="telBox">
          <div class="text">
            <img src="@/assets/image/home/TelTwo.png" alt="" />
            市场服务热线
          </div>
          <div class="number">13601998849</div>
        </div>
      </div>
    </div>
    <!-- <div class="inline">
        <img class="rImg" src="@/assets/image/home/asideInline.gif" alt="">
        <span>在线咨询</span>
      </div> -->
    <div class="back">
      <div class="rImg" @click="goToTop()">
        <div class="img"></div>
        <span>返回顶部</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showGoTop: false //侧边栏 初始值
    }
  },
  mounted() {
    // 添加滚动事件，当滚动距离达到浏览器窗口内部高度时，出现侧边栏
    window.addEventListener("scroll", this.handleSroll, true)
  },
  methods: {
    // 滚动事件
    handleSroll() {
      let scrollTop = document.documentElement.scrollTop
      // 当高度大于100时就让侧边栏出现
      if (scrollTop > 100) {
        this.showGoTop = true
      } else {
        this.showGoTop = false
      }
    },
    // 点击返回顶部
    goToTop() {
      let scrollTop = document.documentElement.scrollTop
      const time = setInterval(() => {
        document.documentElement.scrollTop = scrollTop -= 100
        if (scrollTop <= 0) {
          clearInterval(time)//清除定时器
        }
      }, 10);
    }
  }
}
</script>

<style lang="less" scoped>
// 侧边栏
.aside {
  position: fixed;
  right: 0;
  bottom: 100px;
  width: 83px;
  height: 326px;
  background: #EAF7FF;
  border-radius: 10px 10px 10px 10px;
  font-size: 14px;
  white-space: nowrap;
  border: 1px solid #75C6F8;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;

  .rImg {
    width: 70px;
    height: 70px;
    padding: 0 15px;
    // border: 1px solid red;
    font-size: 14px;
    background: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .img {
      width: 20px;
      height: 20px;
      // border: 1px solid;
      // margin: 9px auto 5px;
    }
  }

  .back {
    cursor: pointer;
  }


  // 公众号
  .gzh {
    position: relative;
    cursor: pointer;

    .img {
      width: 20px;
      height: 20px;
      background: url("@/assets/image/home/aside/wechatBlue.png") 100%;
      background-size: 100% 100%;
    }

    &:hover {
      .rImg {
        background-color: #59A3FF;
        color: #fff;

        .img {
          background: url("@/assets/image/home/aside/wechatWhite.png") 100%;
          background-size: 100% 100%;
        }
      }

      .gzhLeft {
        opacity: 1;
        transition: all 1s linear;
      }
    }

    .gzhLeft {
      opacity: 0;
      position: absolute;
      top: -20px;
      left: -127px;
      background: url("@/assets/image/home/gzhEwmBg.png") 100%;
      background-size: 100% 100%;
      width: 127px;
      height: 120px;
      text-align: center;
      line-height: 108px;

      img {
        width: 90px;
        height: 90px;
        margin-left: -5px;
      }
    }
  }

  // 电话咨询
  .tel {
    position: relative;
    cursor: pointer;
    white-space: nowrap;

    .img {
      width: 20px;
      height: 20px;
      background: url("@/assets/image/home/aside/telBlue.png") 100%;
      background-size: 100% 100%;
    }

    &:hover {
      .rImg {
        background-color: #59A3FF;
        color: #fff;

        .img {
          background: url("@/assets/image/home/aside/TelWhite.png") 100%;
          background-size: 100% 100%;
        }
      }

      .telLeft {
        opacity: 1;
        transition: all 0.5s linear;
      }
    }

    .telLeft {
      opacity: 0;
      width: 170px;
      height: 126px;
      position: absolute;
      top: -20px;
      left: -167px;
      background: url("@/assets/image/home/TelBG.png") 100% no-repeat;
      background-size: 105% 108%;
      text-align: left;
      padding: 0 24px 0 15px;

      .telBox {
        height: 55px;
        width: 100%;
        border-bottom: 1px solid #E4E4E4;

        .text {
          img {
            width: 15px;
            height: 15px;
            margin-right: 8px;
          }

          font-size: 12px;
          font-family: Microsoft YaHei-Light,
          Microsoft YaHei;
          font-weight: 300;
          color: #000000;
          line-height: 12px;
          padding-bottom: 6px;
        }

        .number {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #105FFF;
          line-height: 14px;
          text-indent: 23px;
        }
      }

      .telBox:first-child {
        padding-top: 15px;
      }

      .telBox:last-child {
        border-bottom: 0;
        padding-top: 5px;
      }
    }
  }

  // 返回顶部
  .back {

    .img {
      width: 20px;
      height: 20px;
      background: url("@/assets/image/home/aside/backBlue.png") 100%;
      background-size: 100% 100%;
    }

    &:hover {
      .rImg {
        background-color: #59A3FF;
        color: #fff;

        .img {
          background: url("@/assets/image/home/aside/BackWhite.png") 100%;
          background-size: 100% 100%;
        }
      }
    }


  }

}
</style>