<template>
  <div id="app">
    <router-view />
    <back-top/>
  </div>
</template>


<script>
import BackTop from "@/components/BackTop.vue"
export default {
  components: {
    BackTop
  },
  data() {
    return {}
  },

}
</script>


<style lang="less">
* {
  margin: 0;
  padding: 0;
}
// 解决横向滚动条
html {
  overflow-y: auto;
  overflow-x: hidden;
}

#app {
  font-family: Microsoft YaHei-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
</style>
