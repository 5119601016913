import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 跳转本页面时不报错
const routerRePush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return routerRePush.call(this, location).catch(error => error)
}

const routes = [{
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/newslist', //新闻中心
    name: 'newslist',
    component: () => import('../views/newslist.vue')
  },
  {
    path: '/newsInfo', //新闻资讯
    name: 'newsInfo',
    component: () => import('../views/newsInfo.vue')
  },
  {
    path: '/newsIndustry', //行业视点
    name: 'newsIndustry',
    component: () => import('../views/newsIndustry.vue')
  },
  {
    path: '/newsPolicy', //政策解读
    name: 'newsPolicy',
    component: () => import('../views/newsPolicy.vue')
  },
  {
    path: '/newsdetails',
    name: 'newsdetails',
    component: () => import('../views/newsDetails.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/service.vue')
  },
  {
    path: '/work100',
    name: 'work100',
    component: () => import('../views/work100.vue')
  },
  {
    path: '/getjob', //精准就业
    name: 'getjob',
    component: () => import('../views/getJob.vue')
  },
  {
    path: '/jobdata', //就业大数据
    name: 'jobdata',
    component: () => import('../views/jobData.vue')
  },
  {
    path: '/scientificmange', //科研管理平台
    name: 'scientificmange',
    component: () => import('../views/scientificMange.vue')
  },
  {
    path: '/alltable', //一表通
    name: 'alltable',
    component: () => import('../views/allTable.vue')
  },
  {
    path: '/hightable', //高基表
    name: 'hightable',
    component: () => import('../views/highTable.vue')
  },
  {
    path: '/alumnus', //校友平台
    name: 'alumnus',
    component: () => import('../views/alumnus.vue')
  },
  {
    path: '/project', //项目管理与监测平台
    name: 'project',
    component: () => import('../views/project.vue')
  },
  {
    path: '/recruit', //招贤纳士
    name: 'recruit',
    component: () => import('../views/recruit.vue')
  },
  {
    path: '/aboutus', //关于我们
    name: 'aboutUs',
    component: () => import('../views/aboutUs.vue')
  },
  {
    path: '/joblist', //职位列表
    name: '职位列表',
    component: () => import('../views/jobList.vue')
  },
  {
    path: '/jobdetails', //职位详情
    name: '职位详情',
    component: () => import('../views/jobDetails.vue')
  },
  // {
  //   path: '/test', //测试页面
  //   name: '职位详情',
  //   component: () => import('../views/test.vue')
  // },
  //这个*匹配必须放在最后，将改路由配置放到所有路由的配置信息的最后，否则会其他路由path匹配造成影响。     
  {
    path: '*',
    redirect: '/',
    hidden: true
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

export default router